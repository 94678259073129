import React, { Component } from 'react';
import Script from 'react-load-script';

import GTag from './elements/GTag';
import GTM from './elements/GTM';
import Hotjar from './elements/Hotjar';
import Pixel from './elements/Pixel';

export default class Analytics extends Component {
  render() {
    return !window.__PRERENDERING ? (
      <>
        <GTag />
        <GTM />
        <Hotjar />
        <Pixel />
      </>
    ) : null;
  }
}

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import vw, { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Checkbox = (props) => {
  const { label, name, id } = props;
  return (
    <Root>
      <input name={name} id={id} required type="checkbox" />
      <div className="circle">
        <span />
      </div>
      <label htmlFor={id} className="body darkGrey">
        {label}
      </label>
    </Root>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
};

const Root = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  ${vw('margin-bottom', 40, 40)}
  ${vw('margin-top', 0, 0, 16)}
  ${vw('padding-left', 32)}
  @media ${media.desktop} {
    max-width: ${vwDesktop(800)};
    margin-left: auto;
    margin-right: auto;
  }
  label {
    display: block;
    cursor: pointer;
    color: #333f48;
  }
  input {
    position: absolute;
    top: 50%;
    left: 0;
    cursor: pointer;
    opacity: 0;
    transform: translateY(-50%);
    ${vw('width', 24)}
    ${vw('height', 24)}
    &:checked ~ .circle span {
      opacity: 1;
    }
  }
  .circle {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.color.green};
    pointer-events: none;
    ${vw('width', 24)}
    ${vw('height', 24)}
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      border: none !important;
      background-color: ${({ theme }) => theme.color.green};
      opacity: 0;
      transition: ${({ theme }) => theme.transition};
      &:focus,
      &:active {
        outline: none !important;
        border: none !important;
      }
      ${vw('width', 12)}
      ${vw('height', 12)}
    }
  }
`;

export default Checkbox;

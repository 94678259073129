import React from 'react';
import styled from 'styled-components';

import useIntl from 'components/useIntl';
import Hero from 'components/Layout/Hero';
import ContentBlock from 'components/Layout/ContentBlock';
import LineCallout from 'components/Layout/LineCallout';

import vw from 'src/styles/utils';
import theme from 'src/styles/theme';

const Amenities = () => {
  const { t } = useIntl();

  return (
    <Root>
      <Hero title={t.amenities.hero.title} />
      <ContentBlock
        title={t.amenities.content1.title}
        text={t.amenities.content1.body}
        reverse
        image={require('src/assets/images/building/building-exterior.jpg')}
      />
      <ContentBlock
        title={t.amenities.content2.title}
        text={t.amenities.content2.body}
        image={require('src/assets/images/building/fitness-centre.jpg')}
      />
      <LineCallout text={t.amenities.callout} lineColor={theme.color.green} />
    </Root>
  );
};

export default Amenities;

const Root = styled.div`
  ${vw('margin-bottom', 80, 80, 120)}
`;

import React, { useState } from 'react';
import styled from 'styled-components';
import useIntl from 'components/useIntl';
import PropTypes from 'prop-types';

import Button from 'components/Button';

import floorplanData from 'src/data/floorplans';

import vw, { hexToRGBA } from 'src/styles/utils';
import media from 'src/styles/media';

const filters = ['All', 'Studio', 'One Bed', 'Two Bed', 'Three Bed'];

const Table = (props) => {
  const { activePlan, handlePlan } = props;
  const { t } = useIntl();

  const [data, setData] = React.useState(floorplanData);

  const [filter, setFilter] = useState(0);

  const handleIsPlanActive = (bed, newFilter) => {
    if (newFilter === 0) {
      return true;
    } else if (bed + 1 === newFilter) {
      return true;
    } else {
      return false;
    }
  };

  const handleFilter = (i) => {
    setFilter(i);

    let activePlans = [...floorplanData];
    activePlans = activePlans.filter((plan) => handleIsPlanActive(plan.bed, i));

    setData(activePlans);
  };

  return (
    <Root>
      <Filter>
        <p className="button_label darkGrey">filter by:</p>
        <Wrapper className="buttonWrapper">
          {filters.map((filterOption, i) => (
            <FilterButton
              className="cta uppercase"
              active={i === filter}
              onClick={() => handleFilter(i)}
              key={filterOption}
            >
              {filterOption}
            </FilterButton>
          ))}
        </Wrapper>
      </Filter>

      <Header>
        <p className="col1 button_label uppercase darkGrey">
          {t.floorplans.plan}
        </p>
        <p className="col2 button_label uppercase darkGrey">
          {t.floorplans.type}
        </p>
        <p className="col3 button_label uppercase darkGrey">
          {t.floorplans.size}
        </p>
        <p className="col4 button_label uppercase darkGrey">
          {t.floorplans.level}
        </p>
      </Header>

      <Plans>
        {data.map((plan, i) => (
          <Plan active={activePlan === i} key={i}>
            <Card active={activePlan === i} onClick={() => handlePlan(i)}>
              <div className="col1 name">
                <h2>
                  {plan.display}
                  <span>{plan.displaySub}</span>
                </h2>
              </div>

              <div className="details">
                <div className="col2">
                  <h3 className="uppercase">{plan.type}</h3>
                </div>

                <div className="col3">
                  <h3 className="uppercase">
                    {plan.sqft}{' '}
                    <span className="mobile">{t.floorplans.size}</span>
                  </h3>
                </div>
                <div className="col4">
                  <h3 className="uppercase">
                    {plan.levels.join(', ')}{' '}
                    <span className="mobile">{t.floorplans.level}</span>
                  </h3>
                </div>
                <div className="col5">
                  <View className="cta uppercase" active={activePlan === i}>
                    view plan
                  </View>
                </div>
              </div>
            </Card>

            <Container>
              <img
                src={require(`src/assets/images/floorplans/plans/${plan.id.toUpperCase()}.png`)}
                alt=""
                className="png"
              />

              <Keyplate>
                <img
                  src={require(`src/assets/images/floorplans/keyplates/${plan.id.toUpperCase()}.png`)}
                  alt=""
                  className="thumb"
                />

                <Button
                  text={t.floorplans.download}
                  href={`/pdfs/floorplans/Prec_Rental_Floorplans_${plan.id}.pdf`}
                  lightPink
                />
              </Keyplate>
            </Container>
          </Plan>
        ))}
      </Plans>

      <Disclaimer className="disclaimer darkGrey">
        {t.floorplans.disclaimer}
      </Disclaimer>
    </Root>
  );
};

export default Table;

Table.propTypes = {
  activePlan: PropTypes.number,
  handlePlan: PropTypes.func,
};

const Root = styled.div`
  margin: 0 auto;
  ${vw('padding-right', 16, 60, 200)}
  ${vw('padding-left', 16, 60, 200)}
  ${vw('margin-top', 60, 120, 120)}
  ${vw('padding-bottom', 80, 120, 160)}


  .col1 {
    ${vw('width', 60, 107, 214)}
  }
  .col2 {
    ${vw('width', 'auto', 317, 403)}
  }
  .col3 {
    ${vw('width', 'auto', 200, 300)}
  }
  .col4 {
    ${vw('width', 'auto', 120, 250)}
    ${vw('display', 'none', 'none', 'block')}
  }

  .col5 {
    ${vw('display', 'none', 'none', 'block')}
  }
`;

const Header = styled.div`
  ${vw('padding-left', 0, 20)}
  ${vw('display', 'none', 'flex')}
`;

const Filter = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  ${vw('margin-bottom', 30, 60, 70)}
`;

const Wrapper = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-wrap: wrap;
  ${vw('gap', 12, 12, 24)}
  ${vw('margin-left', 20, 20, 20)}
  .active {
    background: ${({ theme }) => theme.color.lightPink};
  }
`;

const Keyplate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FilterButton = styled.button`
  cursor: pointer;
  display: flex;
  width: max-content;
  justify-content: center;
  align-items: center;
  background: ${({ active, theme }) =>
    active ? theme.color.green : 'transparent'};
  border: 1px solid ${({ theme }) => theme.color.green};
  color: ${({ theme, active }) =>
    active ? theme.color.white : theme.color.darkGrey};
  transition: ${({ theme }) => theme.transition};
  ${vw('height', 25, 37)}
  ${vw('padding-right', 12, 24)}
  ${vw('padding-left', 12, 24)}
  &:hover {
    background: ${({ theme }) => theme.color.greenDark};
    color: ${({ theme }) => theme.color.white};
  }
`;

const Plans = styled.div`
  border-top: 2px solid ${({ theme }) => theme.color.navy};
  border-bottom: 2px solid ${({ theme }) => theme.color.navy};
  ${vw('margin-top', 0, 12, 16)}
  @media ${media.tablet} {
    border-top: 3px solid ${({ theme }) => theme.color.navy};
    border-bottom: 3px solid ${({ theme }) => theme.color.navy};
  }
`;

const Plan = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.gold};
  ${(props) =>
    props.active ? vw('max-height', 999) : vw('max-height', 84, 95, 89)}
  transition: all 0.4s ease;
  overflow: hidden;
  .name {
    position: relative;
    span {
      position: absolute;
      bottom: 0;
      ${vw('font-size', 10, 15)}
      ${vw('letter-spacing', 0.5, 1)}
    }
  }
`;

const Card = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  background-color: ${({ active, theme }) =>
    active ? theme.color.green : 'transparent'};
  color: ${({ theme, active }) =>
    active ? theme.color.white : theme.color.darkGrey};
  transition: ${({ theme }) => theme.transition};
  ${vw('padding-left', 12, 20)}
  ${vw('padding-top', 20, 0)}
  ${vw('height', 83, 94, 88)}
  @media ${media.tablet} {
    align-items: center;
    .details {
      display: flex;
      align-items: center;
    }

    .mobile {
      display: none;
    }
  }
  @media ${media.desktop} {
    &:hover {
      color: ${({ theme }) => theme.color.white};
      background-color: ${({ theme }) => theme.color.green};
      button {
        color: ${({ theme }) => theme.color.white};
      }
    }
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => hexToRGBA(theme.color.green, 0.06)};
  ${vw('flex-direction', 'column', 'column', 'row-reverse')}
  ${vw('align-items', 'center', 'center', 'flex-end')}
  ${vw('padding-top', 40, 40, 80)}
  ${vw('padding-bottom', 40, 40)}
  ${vw('padding-left', 40, 40, 80)}
  ${vw('padding-right', 40, 40, 80)}
  .png {
    ${vw('width', 288, 648, 808)}
    ${vw('margin-bottom', 40, 40, 40)}
  }

  .thumb {
    width: auto;
    ${vw('height', 75, 75, 120)}
    ${vw('margin-bottom', 40)}
    object-fit: contain;
    object-position: center left;
  }
`;

const Disclaimer = styled.div`
  ${vw('margin-top', 20, 35, 16)};
  ${vw('font-size', 10, 10, 10)};
  ${vw('line-height', 12, 12, 12)};
`;

const View = styled.button`
  color: ${({ theme, active }) =>
    active ? theme.color.white : theme.color.darkGrey};
`;

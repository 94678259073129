export default {
  interiors: {
    title: 'Interiors & Amenities',
    images: [
      {
        thumb: require('src/assets/images/gallery/interiors/thumbs/kitchen.jpg'),
        src: require('src/assets/images/gallery/interiors/kitchen.png'),
        alt: 'Kitchen',
      },
      {
        thumb: require('src/assets/images/gallery/interiors/thumbs/ensuite.jpg'),
        src: require('src/assets/images/gallery/interiors/ensuite.png'),
        alt: 'Bathroom',
      },
      {
        thumb: require('src/assets/images/gallery/interiors/thumbs/fitness-centre.jpg'),
        src: require('src/assets/images/gallery/interiors/fitness-centre.png'),
        alt: 'Fitness Centre',
      },
      {
        thumb: require('src/assets/images/gallery/interiors/thumbs/outdoor-terrace.jpg'),
        src: require('src/assets/images/gallery/interiors/outdoor-terrace.png'),
        alt: 'Outdoor Terrace',
      },
      {
        thumb: require('src/assets/images/gallery/interiors/thumbs/lounge.jpg'),
        src: require('src/assets/images/gallery/interiors/lounge.png'),
        alt: 'Lounge',
      },
    ],
  },
  neighbourhood: {
    title: 'Neighbourhood',
    images: [
      {
        thumb: require('src/assets/images/gallery/neighbourhood/thumbs/gallery_01_thumb.jpg'),
        src: require('src/assets/images/gallery/neighbourhood/gallery_01.jpg'),
      },
      {
        thumb: require('src/assets/images/gallery/neighbourhood/thumbs/gallery_02_thumb.jpg'),
        src: require('src/assets/images/gallery/neighbourhood/gallery_02.jpg'),
      },
      {
        thumb: require('src/assets/images/gallery/neighbourhood/thumbs/gallery_03_thumb.jpg'),
        src: require('src/assets/images/gallery/neighbourhood/gallery_03.jpg'),
      },
      {
        thumb: require('src/assets/images/gallery/neighbourhood/thumbs/gallery_04_thumb.jpg'),
        src: require('src/assets/images/gallery/neighbourhood/gallery_04.jpg'),
      },
      {
        thumb: require('src/assets/images/gallery/neighbourhood/thumbs/gallery_05_thumb.jpg'),
        src: require('src/assets/images/gallery/neighbourhood/gallery_05.jpg'),
      },
      {
        thumb: require('src/assets/images/gallery/neighbourhood/thumbs/gallery_06_thumb.jpg'),
        src: require('src/assets/images/gallery/neighbourhood/gallery_06.jpg'),
      },
      {
        thumb: require('src/assets/images/gallery/neighbourhood/thumbs/gallery_07_thumb.jpg'),
        src: require('src/assets/images/gallery/neighbourhood/gallery_07.jpg'),
      },
      {
        thumb: require('src/assets/images/gallery/neighbourhood/thumbs/gallery_08_thumb.jpg'),
        src: require('src/assets/images/gallery/neighbourhood/gallery_08.jpg'),
      },
      {
        thumb: require('src/assets/images/gallery/neighbourhood/thumbs/gallery_09_thumb.jpg'),
        src: require('src/assets/images/gallery/neighbourhood/gallery_09.jpg'),
      },
      {
        thumb: require('src/assets/images/gallery/neighbourhood/thumbs/gallery_10_thumb.jpg'),
        src: require('src/assets/images/gallery/neighbourhood/gallery_10.jpg'),
      },
      {
        thumb: require('src/assets/images/gallery/neighbourhood/thumbs/gallery_11_thumb.jpg'),
        src: require('src/assets/images/gallery/neighbourhood/gallery_11.jpg'),
      },
      {
        thumb: require('src/assets/images/gallery/neighbourhood/thumbs/gallery_12_thumb.jpg'),
        src: require('src/assets/images/gallery/neighbourhood/gallery_12.jpg'),
      },
      {
        thumb: require('src/assets/images/gallery/neighbourhood/thumbs/gallery_13_thumb.jpg'),
        src: require('src/assets/images/gallery/neighbourhood/gallery_13.jpg'),
      },
      {
        thumb: require('src/assets/images/gallery/neighbourhood/thumbs/gallery_14_thumb.jpg'),
        src: require('src/assets/images/gallery/neighbourhood/gallery_14.jpg'),
      },
      {
        thumb: require('src/assets/images/gallery/neighbourhood/thumbs/gallery_15_thumb.jpg'),
        src: require('src/assets/images/gallery/neighbourhood/gallery_15.jpg'),
      },
      {
        thumb: require('src/assets/images/gallery/neighbourhood/thumbs/gallery_16_thumb.jpg'),
        src: require('src/assets/images/gallery/neighbourhood/gallery_16.jpg'),
      },
      {
        thumb: require('src/assets/images/gallery/neighbourhood/thumbs/gallery_17_thumb.jpg'),
        src: require('src/assets/images/gallery/neighbourhood/gallery_17.jpg'),
      },
    ],
  },
};

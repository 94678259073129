import React from 'react';
import styled from 'styled-components';
import useIntl from 'components/useIntl';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Menu = (props) => {
  const { t, getLocaleURL } = useIntl();
  const { isOpen, setIsOpen } = props;

  return (
    <Root
      style={{
        opacity: isOpen ? '1' : '0',
        pointerEvents: isOpen ? 'auto' : 'none',
      }}
    >
      <Grid>
        {t['header'].routes.map((route, i) => (
          <StyledLink
            key={i}
            to={`${getLocaleURL()}${route.path}`}
            className="main_menu"
            onClick={() => setIsOpen(false)}
          >
            {route.text}
          </StyledLink>
        ))}
      </Grid>
    </Root>
  );
};

export default Menu;

Menu.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.color.white};
  width: 100%;
  height: 100vh;
  transition: all 0.4s ease;
  z-index: -1;
  @media ${media.desktop} {
    display: none;
  }
`;

const Grid = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  grid-template-columns: repeat(1, min-content);
  grid-auto-rows: min-content;
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.color.darkGrey};
  text-align: center;
  border-top: 1px solid ${({ theme }) => theme.color.darkGrey};
  &:last-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.color.darkGrey};
  }
  ${vw('padding-top', 12, 20, 0)}
  ${vw('padding-bottom', 12, 20, 0)}
  ${vw('width', 180, 326, 'min-content')}
`;

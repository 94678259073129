export default {
  color: {
    grey: '#A6BBC8',
    darkGrey: '#444444',
    white: '#FFFFFF',
    green: '#85B09A',
    greenDark: '#669C80',
  },
  font: {
    header: 'Gotham',
    family: 'Kessel-105',
  },
  transition: '0.4s ease',
};

import React from 'react';
import styled from 'styled-components';

import Intl from 'src/components/Intl';
import media from 'src/styles/media';
import theme from 'src/styles/theme';
import vw from 'src/styles/utils';

import Calendar from 'src/assets/images/_global/icons/calendar.svg';

const BookAppointment = (props) => {
  return (
    <Intl>
      {(t, { locale }) => (
        <Root href="https://app.squarespacescheduling.com/schedule.php?owner=27028808&location=545%20Sydney%20Avenue%2C%20Coquitlam%2C%20BC" target="_blank">
          <Img
            src={Calendar}
            alt="Calendar icon"
            width="32"
            height="32"
          />
          {t.bookAppointment}
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.a`
  color: white;
  position: fixed;
  font-family: ${theme.font.header};
  line-height: normal;
  font-weight: 500;
  ${vw('letter-spacing', 0.5, 1)};
  ${vw('font-size', 11, 13)};
  ${vw('width', 161, 227)};
  ${vw('height', 48, 69)};
  color: ${theme.color.white};
  background-color: ${theme.color.navy};
  bottom: -10px;
  ${vw('border-top-right-radius', 14, 24)};
  ${vw('border-top-left-radius', 14, 24)};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  left: 0;
  right: 0;
  margin: auto;
  transition: all 0.4s ease;

  &:hover {
    bottom: 0;
  }

  @media ${media.tablet} {
    left: auto;
    ${vw('right', 80)};
  }
`;

const Img = styled.img`
  margin-right: 12px;
  ${vw('width', 28, 32)}
`;

export default BookAppointment;

import { createGlobalStyle } from 'styled-components';
import theme from './theme';
import vw from 'src/styles/utils';
import media from 'src/styles/media';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html,
  body,
  #app {
    width: 100%;
    height: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-stroke: 0;
  }

  body {
    font-family: ${theme.font.family};
    // This is a temporary fix to disable the iframe coming from react-hot-loader in development
    > iframe {
      display: none;
    }
  }

  a,
  button {
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none;
  }

  main {
    text-align: center;
    position: absolute;
    top: 120px;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .greenDark {
    color: ${theme.color.greenDark};
  }

  .green {
    color: ${theme.color.green};
  }

  .white {
    color: ${theme.color.white};
  }

  .darkGrey {
    color: ${theme.color.darkGrey};
  }

  .italic {
    font-style: italic;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .center {
    text-align: center;
  }

  .mobile-desktop {
    ${vw('display', 'block', 'none', 'block')}
  }

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  h1 {
    font-family: ${theme.font.header};
    font-weight: 400;
    font-style: normal;
    line-height: 110%;
    ${vw('font-size', 32, 48)}
    ${vw('letter-spacing', 0.8)}
    &.hero {
      font-family: ${theme.font.header};
      font-weight: 300;
      font-style: normal;
      line-height: 110%;
      ${vw('font-size', 24, 64)}
      ${vw('letter-spacing', 0.8)}
    }
  }

  .pageTitle {
    font-family: ${theme.font.header};
    font-style: normal;
    font-weight: 300;
    text-align: center;
    text-transform: uppercase;
    ${vw('font-size', 24, 36, 48)}
    ${vw('line-height', 32, 42, 58)}
    ${vw('letter-spacing', 0.8)}
  }

  h2 {
    font-family: ${theme.font.header};
    font-weight: 300;
    font-style: normal;
    ${vw('letter-spacing', 0.5)}
    ${vw('font-size', 24, 40)}
    ${vw('line-height', 36, 48)}
  }

  h3 {
    font-family: ${theme.font.header};
    font-weight: 300;
    font-style: normal;
    ${vw('font-size', 16, 16, 20)}
    ${vw('line-height', 22, 22, 32)}
    ${vw('letter-spacing', 'normal', 0.4)}
  }

  h4 {
    font-family: ${theme.font.family};
    font-weight: 400;
    font-style: normal;
    line-height: 100%;
    ${vw('font-size', 11, 21)}
    ${vw('letter-spacing', 1.5, 2)}
  }

  p, .body {
    font-family: ${theme.font.family};
    font-weight: normal;
    font-style: normal;
    line-height: 160%;
    ${vw('font-size', 13, 14)}
    ${vw('letter-spacing', 0.3, 0.4)}
    &.bold {
      font-weight: bold;
    }
    &.footer {
      font-weight: 400;
      ${vw('line-height', 18, 24)}
      ${vw('letter-spacing', 0.5, 1)}
    }
    &.ledmac-footer {
      font-weight: 400;
      ${vw('font-size', 13)}
      ${vw('line-height', 22)}
      ${vw('letter-spacing', 0.3)}
    }
  }

  .small {
    font-family: ${theme.font.family};
    font-weight: 400;
    font-style: normal;
    ${vw('font-size', 10, 13)}
    ${vw('line-height', 18, 20)}
    ${vw('letter-spacing', 'normal', 0.3)}
  }

  .main_menu {
    font-family: ${theme.font.header};
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: all 0.4s ease;
    line-height: 120%;
    ${vw('font-size', 14)}
    ${vw('letter-spacing', 1)}

    &.active {
      color: ${theme.color.green};
    }
  }

  .button_label {
    font-family: ${theme.font.header};
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    ${vw('font-size', 11, 13)}
    ${vw('line-height', 'normal', 20)}
    ${vw('letter-spacing', 1)}
  }

  .cta {
    display: flex;
    align-items: center;
    font-family: ${theme.font.header};
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    transition: all 0.4s ease;
    line-height: 120%;
    ${vw('font-size', 11, 14)}
    ${vw('letter-spacing', 1)}
  }

  .disclaimer {
    font-family: ${theme.font.family};
    font-weight: 400;
    font-style: normal;
    ${vw('font-size', 10)};
    ${vw('line-height', 16)}
    ${vw('letter-spacing', 0.1)}
  }

  .callout {
    font-family: ${theme.font.family};
    font-weight: 400;
    font-style: normal;
    line-height: 140%;
    ${vw('font-size', 10)};
    ${vw('letter-spacing', 1.5)}
  }
`;

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const LineCallout = (props) => {
  const { text, lineColor, small } = props;
  return (
    <Root lineColor={lineColor} small={small}>
      <span />
      <h3 className="uppercase navy">{text}</h3>
      <span />
    </Root>
  );
};

LineCallout.propTypes = {
  text: PropTypes.string,
  lineColor: PropTypes.string,
  small: PropTypes.bool,
};

const Root = styled.div`
  ${vw('padding-left', 16, 0)}
  ${vw('padding-right', 16, 0)}
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${media.desktop} {
    align-items: flex-start;
  }
  text-align: center;
  justify-content: center;
  ${vw('margin-top', 40, 80, 120)}
  h3 {
    ${vw('margin-top', 24, 40, 0)}
    ${vw('margin-bottom', 24, 40, 0)}
    ${vw('margin-left', 0, 0, 40)}
    ${vw('margin-right', 0, 0, 40)}
    ${(props) => vw('width', '100%', 546, props.small ? 400 : 638)}
  }
  span {
    ${vw('margin-top', 11, 14)}
    display: block;
    background-color: ${(props) => props.lineColor};
    height: 1px;
    ${vw('width', 120, 248)}
  }
  @media ${media.desktop} {
    flex-direction: row;
  }
`;

export default LineCallout;

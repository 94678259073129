export default [
  {
    image: require('src/assets/images/developer/projects/01_escala-burnaby.jpg'),
    text: 'Escala, Burnaby',
    small: true,
  },
  {
    image: require('src/assets/images/developer/projects/02_aviara-burnaby.jpg'),
    text: 'Aviara, Burnaby',
    small: true,
  },
  {
    image: require('src/assets/images/developer/projects/03_reflections-burnaby.jpg'),
    text: 'Reflections, Burnaby',
    small: true,
  },
  {
    image: require('src/assets/images/developer/projects/04_waterscapes-kelowna.jpg'),
    text: 'Waterscapes, Kelowna',
  },
  {
    image: require('src/assets/images/developer/projects/05_highpoint-coquitlam.jpg'),
    text: 'Highpoint, Coquitlam',
  },
  {
    image: require('src/assets/images/developer/projects/06_icon-burnaby.jpg'),
    text: 'Icon, Burnaby',
  },
  {
    image: require('src/assets/images/developer/projects/07_sydney-coquitlam.jpg'),
    text: 'Sydney, Coquitlam',
  },
  {
    image: require('src/assets/images/developer/projects/08_azure-burnaby.jpg'),
    text: 'Azure, Burnaby',
  },
  {
    image: require('src/assets/images/developer/projects/09_northgate_village-burnaby.jpg'),
    text: 'Northgate Village, Burnaby',
  },
  {
    image: require('src/assets/images/developer/projects/10_perspectives-burnaby.jpg'),
    text: 'Perspectives, Burnaby',
  },
  {
    image: require('src/assets/images/developer/projects/11_saltaire-white_rock.jpg'),
    text: 'Saltaire, White Rock',
  },
  {
    image: require('src/assets/images/developer/projects/12_dominion-new_westminster.jpg'),
    text: 'Dominion, New Westminster',
  },
];

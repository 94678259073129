import React from 'react';

export default {
  register: 'Register Now',
  visitHomes: 'visit Precidia homes',
  bookAnAppointment: 'Book an Appointment',
  bookAppointment: (
    <>
      Book <br /> Appointment
    </>
  ),
  header: {
    routes: [
      {
        path: '/floorplans',
        text: 'Find Your Home',
      },
      {
        path: '/interiors',
        text: 'Interiors',
      },
      {
        path: '/amenities',
        text: 'Building',
      },
      {
        path: '/neighbourhood',
        text: 'Neighbourhood',
      },
      {
        path: '/gallery',
        text: 'Gallery',
      },
      {
        path: '/developer',
        text: 'Team',
      },
    ],
  },
  footer: {
    column1: {
      title: 'Looking to Purchase?',
    },
    column2: {
      title: 'LOOKING TO RENT?',
    },
    column3: {
      title: 'Location',
    },
    marketing: 'marketing in association with',
    disclaimer:
      'The information contained in this promotional material is for illustrative purposes only and is subject to change without notice. Maps and renderings are artistic representation only. The quality residences at Precidia are developed by LM Precidia Homes Limited Partnership. The developer reserves the right to make modifications or substitutions should they be necessary. E. & O.E.',
    corporate: {
      title: 'Confidence. Quality. Value',
      text: 'At Ledingham McAllister, we are dedicated to creating quality homes in superior locations and providing our customers with long-term value. We are committed to the highest standards in everything we do. Our past was built on it, our future depends on it.',
      privacy: 'Privacy Policy',
    },
  },
  home: {
    header: (
      <>
        Mid-Century <br className="mobile" /> Elegance,{' '}
        <br className="tablet" /> Luxurious <br className="mobile" /> Modern
        Living
      </>
    ),
    hero: {
      title: (
        <>
          MOVE-IN READY
          <br /> RENTAL Homes
        </>
      ),
    },
    intro: (
      <>
        BRAND-NEW, PET FRIENDLY STUDIO TO THREE-BEDROOM RENTAL HOMES
        CONVENIENTLY LOCATED IN THE HEART OF WEST COQUITLAM.
        <br />
        <br />
        STUDIO AND ONE BEDS STARTING AT $1975/MONTH
        <br />
        TWO BEDS STARTING AT $2610/MONTH
        <br />
        THREE BEDS STARTING AT $3300/MONTH
      </>
    ),

    content: {
      block1: {
        title: (
          <>
            Rental homes <br />
            designed with <br />
            you in mind
          </>
        ),
        text: 'The rental homes at Precidia have been carefully designed with you in mind. These professionally managed and pet-friendly homes feature spacious, well-planned floorplans with generous balconies for seamless indoor-outdoor living. Enjoy thoughtful details such as full-sized appliances, in-suite laundry, in addition to parking and bike storage options for your added convenience.',
        buttonLabel: 'See Interiors',
      },
      block2: {
        title: (
          <>
            Convenient,
            <br />
            Everyday Rental <br />
            Amenities
          </>
        ),
        text: 'Just an elevator ride away, engage in a workout session in the fully-equipped fitness centre, challenge your neighbours to a game of shuffleboard in the lounge or let your kids blow off some steam on the playground while getting your daily dose of Vitamin D on the outdoor terrace.',
        buttonLabel: 'explore Amenities',
      },
      block3: {
        title: (
          <>
            Urban Parkside
            <br />
            Living
          </>
        ),
        text: 'Precidia is a harmonious blend of everything you need for the day-to-day, situated on a quiet, yet connected, residential street. Steps to SkyTrain, shopping, restaurants and more, Precidia is perfectly placed for work, wellness and everything in between.',
        buttonLabel: 'explore the neighbourhood',
      },
    },
  },
  developer: {
    hero: {
      title: 'CONFIDENCE.  QUALITY.  VALUE',
      body: 'At Ledingham McAllister we are dedicated to smart development by creating superior new home communities in great locations. For over a century it has been our commitment to exacting standards of design, environmental leadership, engineering, and construction that ensures homes of enduring quality and value. More than 20,000 homes later, we can proudly state that our past was built on it, and our future depends on it.',
    },
    companies: {
      ibi: {
        description:
          'IBI Group™ is a technology-driven design firm, providing architecture, engineering, planning, systems, and technology services to their clients for nearly 50 years.  With more than 2,700 professionals located in over 60 offices around the world, they create responsive, resilient spaces and smart urban environments by bringing the established capabilities from their Intelligence practice into their core Buildings and Infrastructure businesses. From high-rises to hospitals, and transit systems to schools, IBI Group™ shapes the way people live, work, move, learn, and heal in the cities of tomorrow.',
      },
      pwl: {
        description:
          'For 40 years, PWL Partnership’s team of landscape architects, urban designers, and planners have built a reputation for excellence across Canada, the USA, and Asia. Their office has five Partners, four Associates and a team of 30 landscape architects and designers. Based in Vancouver, PWL works hard to establish meaningful and lasting relationships with our client groups, project teams, and local stakeholders. The firm’s experience encompasses complex waterfronts, greenways, transit corridors, urban plazas, streetscapes, neighbourhood parks and parklets.',
      },
      themill: {
        description:
          'The Mill Design Group Inc. was founded in 2011 by interior designer, Janine Wilson.  Based in beautiful North Vancouver, The Mill Design Group Inc. specializes in Multi-Family residential design. With over 18 years of experience in the interior design industry, Janine and her amazing team work with developers to create interiors and home finishes that reflect the best of a neighbourhood while embodying the future buyer’s esthetics and values.  The Mill’s design belief is that interior finishes should be timeless and easy to manage, without sacrificing style and comfort.',
      },
    },
    projects: [
      {
        image: require('src/assets/images/developer/projects/07_sydney-coquitlam.jpg'),
        text: 'Sydney, Coquitlam',
      },
      {
        image: require('src/assets/images/developer/projects/01_escala-burnaby.jpg'),
        text: 'Escala, Burnaby',
        small: true,
      },
      {
        image: require('src/assets/images/developer/projects/02_aviara-burnaby.jpg'),
        text: 'Aviara, Burnaby',
        small: true,
      },
      {
        image: require('src/assets/images/developer/projects/03_reflections-burnaby.jpg'),
        text: 'Reflections, Burnaby',
        small: true,
      },
      {
        image: require('src/assets/images/developer/projects/04_waterscapes-kelowna.jpg'),
        text: 'Waterscapes, Kelowna',
      },
      {
        image: require('src/assets/images/developer/projects/05_highpoint-coquitlam.jpg'),
        text: 'Highpoint, Coquitlam',
      },
      {
        image: require('src/assets/images/developer/projects/06_icon-burnaby.jpg'),
        text: 'Icon, Burnaby',
      },
      {
        image: require('src/assets/images/developer/projects/08_azure-burnaby.jpg'),
        text: 'Azure, Burnaby',
      },
      {
        image: require('src/assets/images/developer/projects/09_northgate_village-burnaby.jpg'),
        text: 'Northgate Village, Burnaby',
      },
      {
        image: require('src/assets/images/developer/projects/10_perspectives-burnaby.jpg'),
        text: 'Perspectives, Burnaby',
      },
      {
        image: require('src/assets/images/developer/projects/11_saltaire-white_rock.jpg'),
        text: 'Saltaire, White Rock',
      },
      {
        image: require('src/assets/images/developer/projects/12_dominion-new_westminster.jpg'),
        text: 'Dominion, New Westminster',
      },
    ],
  },
  form: {
    title: 'Register Now',
    firstname: 'First Name *',
    lastname: 'Last Name *',
    email: 'Email Address *',
    phone: 'Phone Number *',
    zip: 'Postal/Zip Code *',
    select: 'Select One...',

    buyOrRent: 'Are you looking to buy or rent? *',
    buyOrRentOptions: [
      {
        value: 'Buy',
        label: 'Buy',
      },
      {
        value: 'Rent',
        label: 'Rent',
      },
    ],

    howHear: 'How did you hear about Precidia? *',
    howHearOptions: [
      {
        value: 'Online Listing',
        label: 'Online Listing',
      },
      {
        value: 'Walk/Drive By',
        label: 'Walk/Drive By',
      },
      {
        value: 'Friend/Family',
        label: 'Friend/Family',
      },
      {
        value: 'Signage',
        label: 'Signage',
      },
      {
        value: 'Google Search',
        label: 'Google Search',
      },
    ],

    howMany: 'How many bedrooms are you looking for? *',
    howManyOptions: [
      {
        value: 'Studio',
        label: 'Studio',
      },
      {
        value: '1 Bedroom',
        label: '1 Bedroom',
      },
      {
        value: '2 Bedroom',
        label: '2 Bedroom',
      },
      {
        value: '3 Bedroom',
        label: '3 Bedroom',
      },
    ],

    agent: 'Are you an Agent?',
    agentOptions: [
      {
        value: 'false',
        label: 'No',
      },
      {
        value: 'true',
        label: 'Yes',
      },
    ],

    disclaimer:
      'By checking this box, I express my consent to receive future e-communications from Ledingham McAllister, including information about upcoming developments, early access and special offers.*',
    submit: 'submit',
    required: '* Required field',
  },
  gallery: {
    hero: {
      title: 'MOVE-IN READY',
    },
  },
  interiors: {
    hero: {
      title: (
        <>
          Brand-New Homes
          <br /> Designed with you in mind
        </>
      ),
    },
    content: {
      block1: {
        title: (
          <>
            Spacious, Livable <br />
            Rental Homes
          </>
        ),
        text: 'The rental homes at Precidia offer a wide range of studio, one-, two-, and three-bedroom homes carefully designed with you in mind. These professionally managed homes offer spacious and functional floorplans with generous outdoor space for families of all sizes (including your four legged friends).',
      },
      block2: {
        title: 'Purposeful Style',
        text: (
          <>
            Kitchens evoke both style and function with stainless-steel
            appliances and modern quartz countertops with tile backsplash.
            Bathrooms amplify rest and relaxation with sleek semi-frameless
            glass showers or large bath and shower set with contemporary
            Carrara-inspired
            <br className="mobile-desktop" /> wall tile.
          </>
        ),
      },
    },
    features: {
      title: 'Intelligent Design',
      column1: [
        'Professionally managed',
        'Pet-friendly homes',
        'Secure fob entry',
      ],
      column2: [
        'Full-sized appliances',
        'In-suite laundry',
        'Parking & storage options available',
      ],
      download: 'Download Feature Sheet',
    },
  },
  amenities: {
    hero: {
      title: 'Convenient Connected Living',
    },
    content1: {
      title: <>The Perfect Balance</>,
      body: 'Located on a quiet residential street, rental residents are greeted with beautifully manicured gardens and a convenient hotel-style auto court for effortless arrivals and departures. Step into a stylish lobby adorned with familiar faces, ensuring a warm welcome home everytime.',
    },
    content2: {
      title: (
        <>
          Everyday Connected <br />
          Amenities
        </>
      ),
      body: 'Just an elevator ride away, engage in a workout session in the fully-equipped fitness centre, challenge your neighbours to a game of shuffleboard in the lounge or let your kids blow off some steam on the playground while getting your daily dose of Vitamin D on the outdoor terrace.',
    },
    callout:
      'Rental residents will feel peace of mind with entry key fob devices providing secure access to the lobby, amenity spaces, bicycle storage area, and parkade.',
  },
  neighbourhood: {
    hero: {
      title: 'CONNECTED LIVING IN COQUITLAM',
      fullStrapline:
        'Where else can you find yourself situated at the crossroads of serenity and urban flair?',
    },
    desc: 'Located in the Burquitlam neighbourhood of Coquitlam, adjacent to the lush greenery of Vancouver Golf Club and all the urban essentials that Lougheed Town Centre has to offer, Precidia is ideally situated to take advantage of it all.',
    content1: {
      title: (
        <>
          Burgeoning
          <br />
          Burquitlam
        </>
      ),
      text: 'Steps to shopping, restaurants, parks and boundless everyday services and recreation opportunities – Precidia gives you access to everything you need, right at your doorstep.',
    },
    content2: {
      title: <>A World of Opportunity</>,
      text: 'Just a short walk to Lougheed Town Centre SkyTrain-almost next door, you can head to Downtown Vancouver, Metrotown, YVR Airport, and nearby schools such as Simon Fraser University and BCIT in minutes.',
    },
    select: 'Select a Category',
    map: [
      {
        title: 'Transit',
        list: [
          {
            name: 'Lougheed Town Centre Station',
            top: '70%',
            left: '37%',
          },
          {
            name: 'Burquitlam Station',
            top: '44%',
            left: '42%',
          },
          {
            name: 'Production Way (University Station)',
            top: '60%',
            left: '16.843826655764513%',
          },
          {
            name: 'Braid Station',
            top: '96.32352941176471%',
            left: '47.2%',
          },
          {
            name: 'Lake City Way Station',
            top: '59%',
            left: '2.125919869174162%',
          },
        ],
      },
      {
        title: 'Parks',
        list: [
          {
            name: 'Brookmere Park',
            top: '68.44117647058823%',
            left: '45.47424366312347%',
          },
          {
            name: 'Vancouver Golf Club',
            top: '55.911764705882355%',
            left: '56.50613246116108%',
          },
          {
            name: 'Rochester Walkway',
            top: '78.26470588235294%',
            left: '33.580539656582175%',
          },
          {
            name: 'Cameron Park',
            top: '60.794117647058826%',
            left: '30.96402289452167%',
          },
          {
            name: 'Keswick Park',
            top: '72.52941176470588%',
            left: '31.454619787408014%',
          },
          {
            name: 'Blue Mountain Park',
            top: '62.05882352941177%',
            left: '63.73916598528209%',
          },
          {
            name: 'Stoney Creek Trail System',
            top: '50.088235294117645%',
            left: '31.96402289452167%',
          },
          {
            name: 'Mackin Park',
            top: '92.82352941176471%',
            left: '65.70155355682747%',
          },
          {
            name: 'Como Lake Park',
            top: '46.38235294117647%',
            left: '68.82747342600164%',
          },
          {
            name: 'Rochester Park',
            top: '78.91176470588235%',
            left: '75.16107931316435%',
          },
          {
            name: 'Burnaby 200 Conservation Area',
            top: '44.294117647058826%',
            left: '13.246116107931316%',
          },
          {
            name: 'Miller Park & Miller Field',
            top: '35.735294117647058%',
            left: '55.50613246116108%',
          },
          {
            name: 'Burnaby Lake Regional Park',
            top: '78.17647058823529%',
            left: '16.271463614063777%',
          },
          {
            name: 'Burnaby Lake',
            top: '84.61764705882354%',
            left: '3.660670482420278%',
          },
          {
            name: 'Westhill Park',
            top: '6.823529411764706%',
            left: '52%',
          },
          {
            name: 'Rocky Point Park',
            top: '5.088235294117647%',
            left: '92%',
          },
          {
            name: 'Burnaby Mountain Park',
            top: '5.0%',
            left: '1.597710547833197%',
          },
        ],
      },
      {
        title: 'Recreation',
        list: [
          {
            name: 'Cameron Recreation Complex',
            top: '61%',
            left: '31.5%',
          },
          {
            name: 'New YMCA (coming soon)',
            top: '44%',
            left: '40%',
          },
          {
            name: 'Poirier Sport and Leisure Complex',
            top: '59.970588235294116%',
            left: '77.69582992641047%',
          },
          {
            name: 'Spani Outdoor Pool',
            top: '63.26470588235294%',
            left: '96.26901062959935%',
          },
        ],
      },
      {
        title: 'Schools',
        list: [
          {
            name: 'Cameron Elementary',
            top: '63.26470588235294%',
            left: '33.272281275551922%',
          },
          {
            name: 'Roy Stibbs Elementary',
            top: '59.35294117647059%',
            left: '46.86426819296811%',
          },
          {
            name: 'Lord Baden – Powell Elementary',
            top: '73%',
            left: '58.16026165167621%',
          },
          {
            name: 'Mountain View Elementary',
            top: '52.970588235294116%',
            left: '53.97138184791496%',
          },
          {
            name: 'Como Lake Middle School',
            top: '63.26470588235294%',
            left: '68.00981193785773%',
          },
          {
            name: 'Burnaby Mountain Secondary School',
            top: '58.26470588235294%',
            left: '22.668029435813573%',
          },
          {
            name: 'École Banting Middle School',
            top: '37.529411764705884%',
            left: '53.97138184791496%',
          },
          {
            name: 'Miller Park Community School',
            top: '34.441176470588236%',
            left: '52.908421913327885%',
          },
          {
            name: 'Centennial Secondary School',
            top: '62.5%',
            left: '78.26819296811121%',
          },
          {
            name: 'SFU',
            top: '19.5%',
            left: '17.007358953393297%',
          },
        ],
      },
      {
        title: 'Shopping',
        list: [
          {
            name: 'Cariboo Centre',
            top: '67%',
            left: '40.5%',
          },
          {
            name: 'Lougheed Town Centre',
            top: '62%',
            left: '34%',
          },
          {
            name: 'Northgate Village',
            top: '62%',
            left: '40.5%',
          },
          {
            name: 'Burquitlam Plaza',
            top: '43%',
            left: '45%',
          },
          {
            name: 'Hanin Village',
            top: '74%',
            left: '41%',
          },
          {
            name: 'Hannam Supermarket',
            top: '75%',
            left: '38%',
          },
          {
            name: 'Ikea',
            top: '97.82352941176471%',
            left: '61.003270645952576%',
          },
          {
            name: 'Costco',
            top: '65.76470588235294%',
            left: '18.806214227309894%',
          },
          {
            name: 'Rona',
            top: '72.38235294117646%',
            left: '64.06623058053966%',
          },
          {
            name: 'Staples',
            top: '93%',
            left: '69.5%',
          },
          {
            name: 'Canadian Tire',
            top: '94.91176470588235%',
            left: '71.6%',
          },
          {
            name: 'Real Canadian Superstore',
            top: '91.82352941176471%',
            left: '73%',
          },
        ],
      },
      {
        title: 'Food',
        list: [
          {
            name: 'Hanamori Sushi',
            top: '74.17647058823529%',
            left: '44.75715453802126%',
          },
          {
            name: 'Insadong Korean Restaurant',
            top: '76.5%',
            left: '41.6%',
          },
          {
            name: 'White Spot',
            top: '70.61764705882354%',
            left: '40.07767784137367%',
          },
          {
            name: 'Yan’s Garden Chinese',
            top: '75.3529411764706%',
            left: '36.95175797219951%',
          },
          {
            name: 'John B Pub',
            top: '69.88235294117646%',
            left: '61.83973834832379%',
          },
          {
            name: "JOEY's",
            top: '84.11764705882354%',
            left: '47.228945216680295%',
          },
          {
            name: 'Cactus Club Café',
            top: '97.82352941176471%',
            left: '78.26819296811121%',
          },
        ],
      },
    ],
  },
  floorplans: {
    title: (
      <>
        A COLLECTION OF STUDIO TO
        <br />
        THREE-BEDROOM RENTAL HOMES IN
        <br /> THE HEART OF COQUITLAM
      </>
    ),
    plan: 'Plan',
    type: 'Type',
    size: 'Approx. Sq Ft',
    level: 'Level',
    download: 'Download Plan',
    disclaimer:
      'In a continuing effort to meet the challenge of product improvements, we reserve the right to modify or change dimensions, sizes, specifications, plan orientations and materials without notice. All patio, terrace and balcony sizes may vary. Windows, columns, privacy screens, demising walls and guardrail details may vary depending upon the plan and floor level. The quality apartments at Precidia Homes are built by LM Precidia Homes Limited Partnership. E & O.E.',
    views: {
      title: (
        <>
          360º VIEW <br />
          OF A DREAM <br />
          COMMUNITY
        </>
      ),
      drag: 'Drag to pan the view',
    },
  },
  privacy: {
    title: 'Privacy Policy',
    body: (
      <>
        <p>
          This Privacy Policy has been prepared by Ledingham McAllister for
          their required site (herein known as “Precidia Rentals”, “we”, “us”,
          “our”) and sets out the way Ledingham McAllister collects, discloses,
          use, and otherwise manages personal information.
          <br />
          <br />
          The Privacy Policy also describes the privacy practices on the
          Ledingham McAllister required site website (the “website”, the “site”,
          or the “websites”) and through other interactions with consumers.
        </p>

        <h3>Collection and Use of Personal Information</h3>
        <p>
          <span>Guest Registry:</span> When you visit one of our sales centres
          and complete a guest registry form, we collect contact information
          such as your first and last name, your mailing address, your email
          address, how you heard about us and your personal phone numbers. You
          may also choose to provide optional additional information such as
          your current housing situation, your reasons for moving, where you
          currently reside, your desired housing characteristics, family status,
          age group, number of adults and children and your annual household
          income.
          <br />
          <br />
          We only use this information that you have provided on the Guest
          Registry to form a better understanding of your needs, aid you in
          finding a suitable home and to send you tailored communications about
          our developments, products and services.
          <br />
          <br />
          We also use this information on an aggregator basis to help us better
          understand our customers and to improve our products and service
          offerings.
          <br />
          <br />
          <span>Buying a Home:</span> When you complete a CPS (Contract of
          Purchase and Sale) for a residential property through Ledingham
          McAllister at any of our developments, we will collect the information
          you provided on the Agreement including the first and name and contact
          information (such as telephone numbers and mailing address) for the
          purchasers, including the property description, and purchase amount.
          We utilize this information for the purposes of facilitating the
          residential property transaction.
          <br />
          <br />
          Additionally, we will collect pertinent information to verify the
          identity of each purchaser (such as a valid government-issued piece of
          identification) as well as a personal cheque for deposit purposes and
          as required by law, REDMA and Fintrac rules, regulations and policies.
          <br />
          <br />
          <span>Warranty Registration and Requests:</span> We collect certain
          pieces of personal information, including your name, email address,
          mailing address, telephone numbers and property closing date when you
          register for a new home warranty plan in your respective and
          applicable province or when you make a warranty customer care request.
          This information will be used to complete the warranty registration
          and to fulfill your warranty request.
          <br />
          <br />
          <span>Website Registration:</span> In order to obtain access to
          information regarding products and services on any password-protected
          areas of our websites, we may request certain personal information
          such as your name, telephone number, mailing address, email address
          and the corresponding password that you have selected. We use this
          information to provide and administer your online account or service
          your request.
          <br />
          <br />
          <span>Marketing Communications:</span> When you complete and submit a
          guest registration form, or otherwise sign-up to receive information
          regarding our developments or related products and services, we
          collect your contact information such as your name, phone numbers,
          mailing addresses and email address. We will use this information to
          send you communications based on your expressed interests by mail,
          email or telephone. You may opt-out of receiving all future marketing
          and promotional communications at any time by clicking on the
          unsubscribe link included in our email communications, or by
          contacting us with the credentials noted at the bottom of this Privacy
          Policy.
          <br />
          <br />
          <span>Customer Service:</span> Any time that you contact us with a
          comment, complaint or a question, you may be asked for information to
          help us identify yourself (such as your name, address, and your
          telephone number) along with additional information we may need to
          help us promptly answer your question or respond to your comment or
          complaint (e.g: your lot number, warranty enrolment number,
          development, community, etc.) We may retain this information to assist
          you in the future and to improve our customer service and product and
          service offerings. We may additionally use personal information to
          establish and manage our relationship with you and provide quality
          customer service.
        </p>
        <h3>Disclosure and Sharing of Your Personal Information</h3>
        <p>
          We will not under any circumstances disclose, rent, trade, sell or
          otherwise transfer your personal information without your consent,
          except as otherwise outlined herein.
          <br />
          <br />
          <span>Service Providers:</span> Your personal information may be
          transferred (or otherwise made available) to our designated third
          parties or affiliates who provide services on our behalf. As an
          example, we may utilize a service provider for the maintenance of our
          website, including hosting an information form, providing additional
          services related to our site, sending electronic mail or other
          functions related to our business or services provided.
          <br />
          <br />
          Our service providers are given only the information they need to
          perform designated functions, and are not authorized under any
          circumstances to disclose personal information for their own marketing
          purposes.
          <br />
          <br />
          Your personal information may be maintained and processed by us, our
          affiliations and other third-party service providers in Canada, the US
          or other foreign jurisdictions. In the event that personal information
          is transferred to other foreign jurisdictions, it will be subject to
          the laws of that country and may be disclosed to or accessed by their
          respective courts of law (or related parties), local law enforcement
          and governmental authorities in accordance to their laws.
          <br />
          <br />
          <span>Legal/Compliance:</span> Ledingham McAllister, its affiliates,
          sister companies and its Canadian or foreign service providers may
          provide your personal information in response to a search warrant or
          other legally valid order or inquiry to another organization for the
          purposes of investigating any breaches of an agreement, violation of a
          law or detecting, preventing or suppressing fraud, or as otherwise
          permitted or required by applicable Canadian or other foreign laws.
          Your personal information may also be disclosed where necessary for
          the establishment, exercise or defense of legal claims and to prevent
          or investigate loss or harm to persons or property.
          <br />
          <br />
          <span>Sale of Business:</span> Personal information may be provided to
          third parties in connection with a prospective or completed business
          transaction, including a sale or merger (including transfers made as
          part of bankruptcy proceedings or insolvency) involving all or a part
          of Ledingham McAllister or as a part of a corporate reorganization or
          stock sale or additional or other changes in corporate control.
        </p>
        <h3>
          Internet-Based Advertising, Information About our Website and Cookies
        </h3>
        <h4>OVERVIEW</h4>
        <p>
          We and/or our service providers collect IP (Internet protocol)
          addresses from all visitors to the website and other related
          information such as browser type, operating system, page requests and
          an average time spent visiting our website.
          <br />
          <br />
          This information is only used to enable us to understand our site’s
          activity and to improve and monitor the website.
        </p>
        <h4>TRACKING TAGS, WEB BEACONS AND COOKIES</h4>
        <p>
          Our site uses a technology called ’web beacons’ and ‘tracking tags’.
          These systems allow us to understand which pages you frequent on our
          site. Tracer tags like these are utilized to help us learn how we can
          tailor and optimize our website for yourself and other site visitors.
          <br />
          <br />
          Our website utilizes a technology known as ‘cookies.’ Cookies are
          essentially pieces of data that sit on your hard drive so we can
          recognize you again when you return to our site.
          <br />
          <br />
          We utilize cookies on the sections of our site in which you are
          prompted to log in or have boxes which are customizable. In the event
          that you have registered on our site, these cookies will allow us to
          identify who you are and which browser you are using and will allow us
          to recognize that you have previously visited the site and it is may
          be necessary to access your account information (which is
          automatically stored on our computers) in an effort to personalize
          services and deliver products.
          <br />
          <br />
          Cookies provide us and our service providers with information
          pertaining to your utilization of our site (e.g: which sections of the
          site you have frequented) that will then be used to personalize our
          site in accordance with your designated interests and preferences.
          <br />
          <br />
          Our site is not designed to respond to ‘do not track’ signals that are
          received from browsers.
        </p>
        <h4>INTERNET-BASED ADVERTISING</h4>
        <p>
          You may find our advertisements on other websites through the usage of
          our third parties, such as Facebook. These companies may additionally
          use web beacons, tracer tags, cookies to report understand certain
          information surrounding your visits to our site and other sites you
          visit and/or interact to ads, in order to measure the effectiveness of
          our marketing campaigns and to deliver the appropriate ads which are
          relevant to you on and off our website.
          <br />
          <br />
          If you would like to learn additional information about the collection
          and use of information by third parties for behavioural purpose.
          Additionally, if you would like to opt-out of certain third-party
          cookies or selection what online advertisements you choose to receive,
          you may do so by visiting the Digital Advertising Alliance of Canada
          website.
          <br />
          <br />
          If you would like opt-out of internet-based ads from Google, you may
          do so here.
        </p>
        <h4>ANALYTICS</h4>
        <p>
          We may utilize services from a third party such as Google Analytics to
          aid us in obtaining and analyzing information about the sections of
          our site which you frequent. These third parties may use technologies
          as noted above including: web beacons, web server logs and cookies.
          This information will be used to improve and evaluate your customer
          experience and the simplicity and convenience of our website, in
          addition to aiding us in understanding the specific ways in which you
          use our site based on your website visits. If you would like further
          information regarding how Google uses data when on a patterns’ sites
          or applications, you may do so here.
        </p>
        <h4>GOOGLE ANALYTICS</h4>
        <p>
          Google Analytics is a web analytics service offered by Google that
          tracks and reports website traffic. Google uses the data collected to
          track and monitor the use of our service. This data is shared with
          other Google services. Google may use the collected data to
          contextualize and personalize the ads of its own advertising network.
          <br />
          <br />
          You can opt-out of having made your activity on the service available
          to Google Analytics by installing the Google Analytics opt-out browser
          add-on. The add-on prevents the Google Analytics JavaScript (ga.js,
          analytics.js, and dc.js) from sharing information with Google
          Analytics about visits activity.  <br />
          <br />
          For more information on the privacy practices of Google, please visit
          the Google Privacy & Terms web
          page: https://policies.google.com/privacy?hl=en
        </p>
        <h4>FACEBOOK ADVERTISEMENTS</h4>
        <p>
          Facebook offers advertising that targets users based on designed
          audiences and specific objectives. Facebook collects this data based
          on provided and approved user parameters.
          <br />
          <br />
          Alternatively, ads may target you based off of data from your browser
          through the utilization of algorithms. Facebook may use the collected
          data to contextualize and personalize the ads of its own advertising
          network.
          <br />
          <br />
          You can opt-out of ads by going to your Facebook profile, selecting
          settings, ad, and then making modification in the Ad settings (Ads
          based on data from partners) by selecting ‘Not Allowed.’
        </p>
        <h4>SOCIAL MEDIA</h4>
        <p>
          This site may provide you with the opportunity to engage with our
          content on or through third-party social networking sites,
          applications or plug-ins. When you engage with our content on or
          through third-party operated social networking websites, applications
          or plug-ins, you may allow us to have access to certain information
          that is associated with your social media accounts (Username, email,
          name) to deliver the content or as a part of the operation of the
          website, application or plug-in. When you provide information from
          your social media account, we may utilize this information to
          personalize your experience on our site and on the third-party social
          networking websites, applications or plug-ins and to provide you with
          other services or products you may request.
        </p>
        <h4>THIRD PARTY LINKS</h4>
        <p>
          Our site may contain links to other sites that are not owned or
          operated by us, this includes social media websites. The links to our
          website may be featured on third party websites on which we advertise
          on. Except as you provided herein, we will not under any circumstances
          provide any of your personal information to these third parties
          without your explicit consent. These links are provided to third party
          websites as a convenience to you as the site user. Links are not
          intended to act as an endorsement of or a referral to the linked
          sites. These linked sites have independent and separate privacy
          statements, terms of use and notices which we advise you to read very
          carefully. We do not have any control over these websites, and as
          such, have no liability or responsible for the manner in which these
          organizations that operate these linked websites may use, disclose,
          collect, secure or otherwise treat your personal information.
        </p>
        <h3>Retention and Safeguards</h3>
        <p>
          We have applied reasonable administrative, technical and physical
          measures in an effort to protect the personal information in our
          custody and control against loss, theft and unauthorized access
          including the usage, modification and disclosure of information. We
          restrict access to your personal information on a need to know basis
          to employees and authorized service providers who need access in order
          to fulfill their job requirements.
          <br />
          <br />
          Your online access to your personal information may be protected with
          a password that you have selected. We strongly discourage you from
          disclosing or sharing your password with anyone. We will never prompt
          you for your password via any unsolicited communication (such as phone
          calls, email, phone calls, or social media messaging systems).
          <br />
          <br />
          Our personal information retention processes are meant to retain
          personal information of our customers for no longer than necessary for
          the purposes stated above or to otherwise adhere to legal parameters.
        </p>
        <h4>ACESS TO YOUR PERSONAL INFORMATION</h4>
        <p>
          You retain the right to access, update and correct inaccuracies in
          your personal information that we have in our custody and control.
          This is subject to personal exceptions as prescribed by the law.
          <br />
          <br />
          You may request access to update, modify and correct inaccuracies in
          personal information that have in our possession or control by
          emailing, writing us, or calling us through the contact information as
          noted below.
          <br />
          <br />
          We may in turn request certain information for verification purposes
          in order to properly identify you as the appropriate person seeking
          access to their personal information records.
        </p>
        <h4>CHANGES TO THE PRIVACY POLICY</h4>
        <p>
          This privacy policy may be updated periodically to reflect changes to
          our personal information practices in accordance with the law. We will
          post the updated Privacy Policy on our website; your personal
          information will always be treated in accordance to what is noted
          within the Privacy Policy in place at the time your personal
          information was collected, unless you otherwise consent.
        </p>
        <h4>CONTACT US</h4>
        <p>
          If you have any questions about this Privacy Policy, please contact
          us:
          <br />
          <br />
          By email:{' '}
          <a href="mailto:admin@rentatprecidia.com">admin@rentatprecidia.com</a>
          <br />
          By visiting this page on our website:{' '}
          <a
            href="https://rentatprecidia.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://rentatprecidia.com/
          </a>
          <br />
          By phone number: <a href="tel:XXX-XXX-XXXX">XXX-XXX-XXXX</a>
          <br />
        </p>
      </>
    ),
  },
  notFound: {
    title: (
      <>
        Sorry! <br />
        We Apologize
      </>
    ),
    strapline: '404 Not Found',
    body: (
      <>
        Looks like something went wrong here. <br />
        The page you are looking for does not exist.
      </>
    ),
    button: 'Back to Homepage',
  },
  thankYou: {
    title: 'Thank You',
    body: (
      <>
        Thank you for registering for Precidia Rental by Ledingham McAllister.{' '}
        <br />
        Your submission has been received and a representative will be in touch
        with you soon.
      </>
    ),
    button: 'Back to Homepage',
  },
};

import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css, keyframes } from 'styled-components';

import useIntl from 'components/useIntl';

import vw from 'src/styles/utils';

const Hero = () => {
  const { t } = useIntl();

  const hasLoaded = useSelector((state) => state.load.hasLoaded);

  return (
    <Root id="hero" hasLoaded={hasLoaded}>
      <HeroLogo
        src={require('src/assets/images/_global/logos/logo-precidia-rentals-white.svg')}
        alt="Precidia Rentals"
        hasPageLoad={hasLoaded}
      />
      <h1 className="hero uppercase white">{t.home.hero.title}</h1>
    </Root>
  );
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeInCss = css`
  animation: ${fadeIn} 0.4s ease forwards 2.5s;
`;

const Root = styled.div`
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 80%
    ),
    url(${require('src/assets/images/home/hero.jpg')});
  background-size: cover;
  background-position: bottom;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  transition: background-position 3s ease-out;
  position: relative;
  ${vw('padding-top', 170, 265, 0)}
  ${vw('padding-left', 20, 34, 175)}
  ${vw('padding-right', 20, 34, 175)}
  ${vw('justify-content', 'flex-start', 'flex-start', 'center')}
  h1 {
    opacity: 0;
    text-align: center;
    line-height: normal;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    ${(props) => props.hasLoaded && fadeInCss}
  }
`;

const HeroLogo = styled.img`
  display: block;
  position: absolute;
  ${vw('width', 93, 197)}
  ${vw('top', 28, 20, 24)}
  ${vw('left', 13, 34, 62)}
`;

export default Hero;

import React from 'react';
import styled from 'styled-components';

import useIntl from 'components/useIntl';
import Slick from '../elements/Slick';

import vw from 'src/styles/utils';

const Hero = () => {
  const { t } = useIntl();

  return (
    <Root>
      <ImageWrapper>
        <Image
          src={require('src/assets/images/_global/logos/ledmac-logo.svg')}
          width="400"
        />
        <h4 className="darkGrey uppercase center">{t.developer.hero.title}</h4>
      </ImageWrapper>

      <TextWrapper>
        <p className="darkGrey center">{t.developer.hero.body}</p>
      </TextWrapper>

      <Slick images={t.developer.projects} />
    </Root>
  );
};

export default Hero;

const Root = styled.div`
  ${vw('padding-top', 132, 200, 240)}
  h4 {
    ${vw('margin-top', 35, 67, 90)}
    ${vw('margin-bottom', 32, 20)}
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${vw('padding-left', 16, 24, 48)}
  ${vw('padding-right', 16, 24, 48)}
`;

const Image = styled.img`
  ${vw('width', 180, 321)}
`;

const TextWrapper = styled.div`
  ${vw('width', 300, 600, 848)}
  margin: auto;
`;

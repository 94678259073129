// Constants
export const LOAD__SETLOADED = 'LOAD::SETLOADED';

// Actions
export const setHasLoaded = (hasLoaded) => ({
  type: LOAD__SETLOADED,
  hasLoaded,
});

// Initial State
const initialState = {
  hasLoaded: false,
};

// Reducer
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case LOAD__SETLOADED:
      return {
        ...state,
        hasLoaded: action.hasLoaded,
      };
    default:
      return state;
  }
};

import React from 'react';
import styled from 'styled-components';

import Button from 'components/Button';

import media from 'src/styles/media';
import vw from 'src/styles/utils';

const RegisterInfo = () => {
  return (
    <Root>
      <Button
        text="download application form"
        className="download"
        href="/pdfs/Application-for-Tenancy-Fillable.pdf"
      />
      <Button
        text="Book an Appointment"
        className="download appt"
        href="https://app.acuityscheduling.com/schedule.php?owner=20816987&location=689%20Whiting%20Way"
      />
      <InfoWrapper>
        <Info>
          <p className="button_label green">Contact us</p>
          <p>
            <a href="tel:236-308-5989">236.308.5989</a>
          </p>
          <p className="darkGrey">
            <a href="mailto:admin@rentatprecidia.com">
              admin@rentatprecidia.com
            </a>
          </p>
        </Info>
        <Info>
          <p className="button_label green">Visit us</p>
          <p className="darkGrey">
            <a
              href="https://www.google.com/maps/place/689+Whiting+Way,+Coquitlam,+BC/@49.2579248,-122.8942021,17z/data=!3m1!4b1!4m5!3m4!1s0x54867849db6b258b:0x888a307aa99586d3!8m2!3d49.2579213!4d-122.8916272?entry=ttu"
              target="_blank"
              rel="noopener noreferrer"
            >
              689 Whiting Way, Coquitlam
            </a>
          </p>
        </Info>
        <Info>
          <p className="button_label green">Hours of operation</p>
          {/* <p>
            <span>Monday</span>
            <span>9 AM - 6 PM</span>
          </p>
          <p>
            <span>Tuesday</span>
            <span>9 AM - 6 PM</span>
          </p>
          <p>
            <span>Wednesday</span>
            <span>9 AM - 6 PM</span>
          </p>
          <p>
            <span>Thursday</span>
            <span>9 AM - 6 PM</span>
          </p>
          <p>
            <span>Friday</span>
            <span>9 AM - 6 PM</span>
          </p>
          <p>
            <span>Saturday</span>
            <span>9 AM - 6 PM</span>
          </p>
          <p>
            <span>Sunday</span>
            <span>9 AM - 6 PM</span>
          </p> */}
          <p className="darkGrey">By appointment only.</p>
        </Info>
      </InfoWrapper>
    </Root>
  );
};

export default RegisterInfo;

const Root = styled.div`
  ${vw('margin-top', 17, 40, 0)}
  ${vw('width', '100%', '100%', 400)}
  .download, .cta {
    width: 100%;
  }
  .appt {
    ${vw('margin-top', 20, 30)}
  }
`;

const InfoWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.color.darkGrey};
  ${vw('padding-top', 17, 40, 50)}
  ${vw('padding-bottom', 34, 60, 90)}
  ${vw('padding-left', 17, 60, 80)}
  ${vw('padding-right', 17, 60, 80)}
  ${vw('margin-top', 10, 20, 66)}
`;

const Info = styled.div`
  ${vw('margin-top', 20, 20, 40)}
  .button_label {
    ${vw('margin-bottom', 8, 8, 8)}
  }
  a {
    color: ${({ theme }) => theme.color.darkGrey};
  }
`;

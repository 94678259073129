import React from 'react';
import styled from 'styled-components';

import Hero from './sections/Hero';
// import Companies from './sections/Companies';

import vw from 'src/styles/utils';

const Developer = () => {
  return (
    <Root>
      <Hero />
      {/* <Companies /> */}
    </Root>
  );
};

export default Developer;

const Root = styled.div`
  ${vw('padding-bottom', 80, 120, 160)}
`;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Nav from './elements/Nav';
import Menu from './elements/Menu';
import { useLocation } from 'react-router-dom';
import { useScrollDown, useHasScrolledPast } from 'bam-storybook';

import vw from 'src/styles/utils';
const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHome, setIsHome] = useState(false);

  const location = useLocation();

  const hasScrolledPast = useHasScrolledPast(100);
  const isScrollingDown = useScrollDown(
    (document.getElementById('hero') &&
      document.getElementById('hero').clientHeight) ||
      1000
  );

  useEffect(() => {
    const home =
      location.pathname.replaceAll('/', '') === '' ||
      location.pathname.replaceAll('/', '') === 'ko' ||
      location.pathname.replaceAll('/', '') === 'tc';

    setIsHome(home);
  }, [location.pathname]);

  return (
    <Root
      hasScrolledPast={hasScrolledPast}
      isScrollingDown={isScrollingDown}
      isHome={isHome}
    >
      <Nav
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        hasScrolledPast={hasScrolledPast}
        isScrollingDown={isScrollingDown}
        isHome={isHome}
      />
      <Menu isOpen={isOpen} setIsOpen={setIsOpen} />
    </Root>
  );
};

export default Header;

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  transition: ${({ theme }) => theme.transition};
`;

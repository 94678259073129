import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import vw from 'src/styles/utils';

const Title = (props) => {
  const { text } = props;

  return (
    <Root>
      <h1 className="pageTitle green">{text}</h1>
    </Root>
  );
};

export default Title;

Title.propTypes = {
  text: PropTypes.string,
};

const Root = styled.div`
  ${vw('padding-top', 132, 200, 240)}
  ${vw('padding-right', 16, 60, 48)}
  ${vw('padding-left', 16, 60, 48)}
  h1 {
    margin: 0 auto;
  }
`;

import useIntl from 'components/useIntl';
import React from 'react';
import styled from 'styled-components';

import ContentBlock from 'components/Layout/ContentBlock';
import Hero from 'components/Layout/Hero';
import Features from './sections/Features';

const Interiors = () => {
  const { t } = useIntl();

  return (
    <Root>
      <Hero title={t.interiors.hero.title} gradient />

      <ContentBlock
        title={t.interiors.content.block1.title}
        text={t.interiors.content.block1.text}
        image={require('src/assets/images/interiors/kitchen.jpg')}
        reverse
      />
      <ContentBlock
        title={t.interiors.content.block2.title}
        text={t.interiors.content.block2.text}
        image={require('src/assets/images/interiors/ensuite.jpg')}
      />
      <Features />
    </Root>
  );
};

export default Interiors;

const Root = styled.div``;

module.exports = {
  defaultLocale: 'en',
  locales: ['en'],
  prerender: true,
  analytics: {
    gtag: '',
    gtmId: 'GTM-TR6PX7LX',
    optimizeId: '',
    hotjarId: '',
  },
  seo: {
    title: 'Precidia Rentals',
  },
  recaptcha: '6Lcm4DgpAAAAAJvPWMtbqUgzXKqgKHlZO0ACcas3',
  stage: {
    mobile: 320,
    tablet: 768,
    desktop: 1760,
  },
  mediaQuery: {
    tablet: 576,
    desktop: 992,
  },
};

import React from 'react';
import styled from 'styled-components';
import useIntl from 'components/useIntl';

import Title from 'components/Layout/Title';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Privacy = () => {
  const { t } = useIntl();

  return (
    <Root>
      <Title text={t.privacy.title} />
      <Body>{t.privacy.body}</Body>
    </Root>
  );
};

export default Privacy;

const Root = styled.div``;

const Body = styled.div`
  color: ${({ theme }) => theme.color.darkGrey};
  ${vw('padding-right', 16, 60, 207)}
  ${vw('padding-bottom', 80, 120, 160)}
  ${vw('padding-left', 16, 60, 207)}
  ${vw('margin-top', 40, 60, 80)}
  span {
    font-weight: bold;
  }
  h3 {
    display: block;
    color: ${({ theme }) => theme.color.darkGrey};
    ${vw('margin-top', 40)}
    ${vw('margin-bottom', 12)}
  }

  h4 {
    font-family: ${({ theme }) => theme.font.family};
    font-weight: 400;
    color: ${({ theme }) => theme.color.darkGrey};
    ${vw('font-size', 14)}
    ${vw('line-height', 22)}
    ${vw('letter-spacing', 3)}
    ${vw('margin-top', 12)}
    ${vw('margin-bottom', 2)}
  }
  a {
    color: ${({ theme }) => theme.color.darkGrey};
    @media ${media.desktop} {
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

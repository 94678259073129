import PropTypes from 'prop-types';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';
import { useSelector } from 'react-redux';

import useIntl from 'components/useIntl';
import Button from 'components/Button';

import media from 'src/styles/media';
import vw from 'src/styles/utils';

const Nav = (props) => {
  const { t, getLocaleURL } = useIntl();
  const { isOpen, setIsOpen, hasScrolledPast, isScrollingDown, isHome } = props;

  const location = useLocation();

  const hasLoaded = useSelector((state) => state.load.hasLoaded);

  const handleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Root
      hasScrolledPast={hasScrolledPast}
      isScrollingDown={isScrollingDown}
      hasLoaded={hasLoaded}
      isHome={isHome}
    >
      <Logo
        to={`${getLocaleURL()}`}
        isHome={isHome}
        hasScrolledPast={hasScrolledPast}
      >
        <img
          src={require('src/assets/images/_global/logos/logo-precidia-rentals-green.svg')}
          alt="Precidia Rentals"
        />
      </Logo>
      <Hamburger
        isOpen={isOpen}
        onClick={handleMenu}
        isHome={isHome}
        hasScrolledPast={hasScrolledPast}
      >
        <span />
        <span />
        <span />
      </Hamburger>

      <Container>
        <Links>
          {t['header'].routes.map((route, i) => (
            <StyledLink
              active={location.pathname.includes(route.path)}
              key={i}
              to={`${getLocaleURL()}${route.path}`}
              className="main_menu"
              hasScrolledPast={hasScrolledPast}
              isHome={isHome}
            >
              {route.text}
            </StyledLink>
          ))}
        </Links>
        <Button to={`${getLocaleURL()}/register`} text={t['register']} />
      </Container>
    </Root>
  );
};

export default Nav;

Nav.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,

  hasScrolledPast: PropTypes.bool,
  isScrollDown: PropTypes.bool,
  isHome: PropTypes.bool,
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeInCss = css`
  animation: ${fadeIn} 0.4s ease forwards 2.5s;
`;

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  transition: ${({ theme }) => theme.transition};
  opacity: ${(props) => (props.isHome ? 0 : 1)};
  box-shadow: ${({ isHome, hasScrolledPast }) =>
    isHome && !hasScrolledPast
      ? 'none'
      : '0px 1px 12px 0px rgba(68, 68, 68, 0.07)'};
  ${(props) => props.isHome && props.hasLoaded && fadeInCss}
  transform: ${(props) =>
    props.isScrollingDown && props.isHome
      ? 'translateY(-100%)'
      : 'translateY(0)'};
  background-color: ${(props) =>
    props.hasScrolledPast || !props.isHome
      ? props.theme.color.white
      : 'transparent'};
  ${(props) =>
    props.hasScrolledPast || !props.isHome
      ? vw('padding-top', 28, 20, 20)
      : vw('padding-top', 28, 20, 40)}
  ${(props) =>
    props.hasScrolledPast || !props.isHome
      ? vw('padding-bottom', 28, 20, 20)
      : vw('padding-bottom', 28, 20, 40)}
  ${vw('padding-left', 16, 30, 62)}
  ${vw('padding-right', 12, 30, 62)}
`;

const Logo = styled(Link)`
  position: relative;
  transition: 0.4s ease;
  overflow: hidden;
  ${(props) =>
    props.hasScrolledPast
      ? vw('max-height', 27, 42, 42)
      : vw('max-height', 50, 108, 108)}
  ${vw('height', 50, 106, 106)}
  ${vw('width', 93, 197)}
  img {
    display: block;
    transition: ${({ theme }) => theme.transition};
    opacity: ${(props) => (props.isHome && !props.hasScrolledPast ? 0 : 1)};
    position: absolute;
    bottom: 0;
    left: 0;
    transition: ${({ theme }) => theme.transition};
    height: auto;
    ${vw('width', 93, 197)}/* ${vw('height', 50, 108)} */
  }
`;

const Hamburger = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${vw('width', 20)}
  ${vw('height', 14)}
  cursor: pointer;

  span {
    display: block;
    width: 100%;
    height: 2px;
    background-color: ${({ theme, isOpen, isHome, hasScrolledPast }) =>
      isHome && !hasScrolledPast && !isOpen
        ? theme.color.white
        : theme.color.darkGrey};
    position: absolute;
    transition: all 0.4s ease;

    &:nth-of-type(1) {
      top: ${(props) => (props.isOpen ? '50%' : '0')};
      left: 0;
      transform: ${(props) =>
        props.isOpen ? 'translateY(-50%) rotate(45deg)' : ''};
    }

    &:nth-of-type(2) {
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      opacity: ${(props) => (props.isOpen ? '0' : '1')};
    }

    &:nth-of-type(3) {
      top: ${(props) => (props.isOpen ? '50%' : 'calc(100% - 2px)')};
      left: 0;
      transform: ${(props) =>
        props.isOpen ? 'translateY(-50%) rotate(-45deg)' : ''};
    }
  }
  @media ${media.desktop} {
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Links = styled.div`
  display: none;
  @media ${media.desktop} {
    display: flex;
    align-items: center;
  }
`;

const StyledLink = styled(Link)`
  color: ${({ active, isHome, hasScrolledPast, theme }) =>
    active
      ? theme.color.green
      : !isHome || hasScrolledPast
      ? theme.color.darkGrey
      : 'white'};
  text-decoration: none;
  ${vw('margin-right', 66)}
  @media ${media.desktop} {
    &:hover {
      color: ${({ theme }) => theme.color.green};
    }
  }
`;

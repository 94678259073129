import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import useIntl from 'components/useIntl';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

import data from 'src/data/map';

const Map = (props) => {
  const { activeCategory, activePoint, handlePoint } = props;

  const { t } = useIntl();

  return (
    <Root>
      <img
        src={require('src/assets/images/neighbourhood/map-lg-logo.jpg')}
        alt="map"
      />

      {activeCategory !== null &&
        t.neighbourhood.map[activeCategory].list.map((point, i) => (
          <Callout
            key={i}
            active={activePoint === i}
            top={point.top}
            left={point.left}
            onClick={() => handlePoint(i)}
          >
            <Circle>
              <p className="bold white">{i + 1}</p>
            </Circle>

            <Text
              style={{
                opacity: activePoint === i ? '1' : '0',
                pointerEvents: activePoint === i ? 'auto' : 'none',
              }}
            >
              <span className="callout white">{point.name}</span>
            </Text>
          </Callout>
        ))}
    </Root>
  );
};

export default Map;

Map.propTypes = {
  activeCategory: PropTypes.number,
  activePoint: PropTypes.number,
  handlePoint: PropTypes.func,
};

const Root = styled.div`
  position: relative;
  ${vw('width', 320, 708, 930)};
  ${vw('height', 234, 517, 680)};
  ${vw('margin-right', 0, 30, 0)}
  ${vw('margin-left', 0, 30, 0)}
  ${vw('margin-bottom', 40, 40, 0)}
  img {
    /* object-fit: cover;
    object-position: center;
    ${vw('width', '100%', '100%', 1223)}
    ${vw('height', 'auto', 'auto', 680)} */
    width: 100%;
    height: 100%;
  }
`;

const Callout = styled.div`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  transform: translate(-50%, -50%);
  z-index: ${(props) => (props.active ? '1' : '0')};
`;

const Circle = styled.div`
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.darkGrey};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0px 0px 5px rgba(51, 63, 72, 0.6));
  ${vw('width', 15, 20)}
  ${vw('height', 15, 20)}
`;

const Text = styled.div`
  display: none;
  @media ${media.tablet} {
    position: absolute;
    left: 140%;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.color.darkGrey};
    white-space: nowrap;
    transition: ${({ theme }) => theme.transition};
    filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.3));
    ${vw('height', 28, 28)}
    ${vw('padding-right', 7, 10, 22)}
    ${vw('padding-left', 7, 10, 22)}
    ${vw('bottom', 28, 28)}

    &:after {
      content: '';
      position: absolute;
      left: -11px;
      top: 50%;
      transform: rotate(90deg) translateX(-28%);
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid ${({ theme }) => theme.color.darkGrey};
      ${vw('bottom', -7)}
    }
  }
`;

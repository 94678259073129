import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Title from './Title';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Hero = (props) => {
  const { title, src, smallStrapline, fullStrapline } = props;
  return (
    <Root>
      <Title text={title} small />

      {src && <img src={src} alt="" className="render" />}
      {smallStrapline && (
        <Small>
          <h3>{smallStrapline}</h3>
        </Small>
      )}

      {fullStrapline && (
        <Full>
          <h3>{fullStrapline}</h3>
        </Full>
      )}
    </Root>
  );
};

export default Hero;

Hero.propTypes = {
  title: PropTypes.string,
  src: PropTypes.string,
  smallStrapline: PropTypes.string,
  fullStrapline: PropTypes.string,
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.color.darkGrey};
  .render {
    ${vw('margin-top', 20, 40, 60)}
    ${vw('padding-right', 16, 60, 200)}
    ${vw('padding-left', 16, 60, 200)}
  }
`;

const Small = styled.div`
  ${vw('margin-top', 10)}
  ${vw('padding-right', 16, 60, 48)}
  ${vw('padding-left', 16, 60, 48)}
`;

const Full = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${vw('margin-top', 20, 20, 40)}
  ${vw('padding-right', 16, 60, 48)}
  ${vw('padding-left', 16, 60, 48)}
  img {
    display: none;
  }

  h3 {
    text-align: center;
    ${vw('width', '100%', 600, 648)}
    text-transform: uppercase;
  }

  @media ${media.desktop} {
    img {
      display: block;
      ${vw('width', 322)}
    }
  }
`;

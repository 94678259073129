import React from 'react';
import styled from 'styled-components';
import useIntl from 'components/useIntl';

import Hero from 'components/Layout/Hero';
import Slick from './elements/Slick';

import data from 'src/data/gallery';

const Gallery = () => {
  const { t } = useIntl();

  return (
    <Root>
      <Hero
        title={t.gallery.hero.title}
        src={require('src/assets/images/gallery/hero.jpg')}
      />
      <Slick title={data.interiors.title} images={data.interiors.images} />
      <Slick
        title={data.neighbourhood.title}
        images={data.neighbourhood.images}
      />
    </Root>
  );
};

export default Gallery;

const Root = styled.div``;

import { useSelector } from 'react-redux';

import appConfig from 'src/config/app.conf';

const useIntl = () => {
  const t = useSelector((state) => state.intl.translations);
  const locale = useSelector((state) => state.intl.locale);

  const getLocaleURL = () => {
    return locale === appConfig.defaultLocale ? '' : `/${locale}`;
  };

  return { t, locale, getLocaleURL };
};

export default useIntl;

import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

// Icons
import ArrowLeft from 'src/components/Icons/ArrowLeft';
import ArrowRight from 'src/components/Icons/ArrowRight';

// Styles
import vw from 'src/styles/utils';
import media from 'src/styles/media';

// Slick styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Slick = (props) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    arrows: true,
    nextArrow: (
      <Arrow
        src={require('src/assets/images/_global/icons/arrow-right.svg')}
        alt="Next"
      />
    ),
    prevArrow: (
      <Arrow
        src={require('src/assets/images/_global/icons/arrow-left.svg')}
        alt="Prev"
      />
    ),
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  return (
    <Root>
      <Title>{props.title}</Title>
      <Slider {...settings}>
        {props.images.map((image, index) => (
          <Card key={index}>
            <CardContent>
              <CardImage className="card-image" bg={image.image}></CardImage>
            </CardContent>

            <p className="caption">{image.text}</p>
          </Card>
        ))}
      </Slider>
    </Root>
  );
};

const Root = styled.div`
  ${vw('padding-left', 36, 36, 64)}
  ${vw('padding-right', 36, 36, 64)}
  ${vw('padding-top', 40, 60, 80)}

  .slick-slider {
    ${vw('margin-left', 0)}
    ${vw('margin-right', 0)}
  }

  .slick-prev,
  .slick-next {
    height: auto;
    ${vw('width', 20, 20, 25)}
  }
  .slick-prev {
    ${vw('left', -25, -25, -45)}
  }
  .slick-next {
    ${vw('right', -25, -25, -45)}
  }

  .caption {
    text-align: left;
  }
`;

const Title = styled.h3`
  font-weight: 300;
  text-transform: uppercase;
  ${vw('margin-bottom', 16, 30)}
  ${vw('font-size', 18)}
  ${vw('line-height', 24)}
  ${vw('letter-spacing', 7)}
  text-align: center;

  @media ${media.tablet} {
    text-align: left;
  }
`;

const Card = styled.div`
  ${vw('padding-left', 5, 10)}
  ${vw('padding-right', 5, 10)}
  text-align: center;
`;

const CardContent = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  ${vw('height', 200, 320, 400)}
  ${vw('margin-bottom', 8, 12)}
`;

const CardImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.bg});
  background-position: center;
  background-size: cover;
  transition: all 0.8s ease 0s;
`;

const Arrow = styled.img``;

export default Slick;

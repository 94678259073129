import React, { useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

import Spotlight from './Spotlight';

import vw from 'src/styles/utils';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Slick = (props) => {
  const [showSpotlight, setShowSpotlight] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleOpenGallery = (index) => {
    setShowSpotlight(true);
    setCurrentIndex(index);
    document.body.style.overflow = 'hidden';
  };

  const closeSpotlight = () => {
    document.body.style.overflow = '';
    setShowSpotlight(false);
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: (
      <Arrow
        src={require('src/assets/images/_global/icons/arrow-right.svg')}
        alt="Next"
      />
    ),
    prevArrow: (
      <Arrow
        src={require('src/assets/images/_global/icons/arrow-left.svg')}
        alt="Prev"
      />
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Root>
      <Spotlight
        title={props.title}
        closeHandler={closeSpotlight}
        images={props.images}
        index={currentIndex}
        active={showSpotlight}
      />
      <Title className="darkGrey uppercase">{props.title}</Title>
      <Container>
        <Slider {...settings}>
          {props.images.map((image, index) => (
            <Card key={index} onClick={() => handleOpenGallery(index)}>
              <CardContent>
                <CardImage className="card-image" bg={image.thumb}></CardImage>
                <Plus
                  src={require('src/assets/images/_global/icons/open_modal.svg')}
                  alt="Open"
                />
              </CardContent>

              <p className="darkGrey">{image.alt}</p>
            </Card>
          ))}
        </Slider>
      </Container>
    </Root>
  );
};

const Root = styled.div`
  ${vw('padding-left', 16, 60, 100)}
  ${vw('padding-right', 16, 60, 100)}
  ${vw('margin-top', 60, 80, 120)}
  ${vw('margin-bottom', 60, 80, 120)}

  .slick-slider {
    ${vw('margin-left', 18, 0)}
    ${vw('margin-right', 18, 0)}
  }
`;

const Container = styled.div`
  .slick-prev,
  .slick-next {
    height: auto;
    ${vw('width', 20, 25)}
  }
  .slick-prev {
    ${vw('left', -25, -55)}
  }
  .slick-next {
    ${vw('right', -25, -25, 0)}
  }
`;

const Title = styled.h3`
  text-align: left;
  position: relative;
  ${vw('margin-bottom', 48, 32)}
`;

const Card = styled.div`
  ${vw('width', 'auto', 'auto', 398)}
  ${vw('padding-left', 0, 37.5, 0)}
  ${vw('padding-right', 0, 37.5, 0)}
  ${vw('text-align', 'center', 'left')}
`;

const CardContent = styled.div`
  position: relative;
  overflow: hidden;
  cursor: pointer;
  ${vw('height', 148, 200)}
  ${vw('margin-bottom', 8, 12)}
  ${vw('width', '100%', '100%', 323)}
  &:focus,
  &:hover {
    .card-image {
      transform: scale(1.1);
    }
  }
`;

const Plus = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${vw('width', 30, 30, 40)}
  ${vw('height', 30, 30, 40)}
`;

const CardImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.bg});
  background-position: center;
  background-size: cover;
  transition: all 0.8s ease 0s;
`;

const Arrow = styled.img``;

export default Slick;

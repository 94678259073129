import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Button = (props) => {
  const { text, to, href, handleClick, ghost, disabled, type, whiteHover } =
    props;

  const handleAs = () => {
    if (to) {
      return Link;
    } else if (href) {
      return 'a';
    } else {
      return 'button';
    }
  };

  return (
    <Root {...props}>
      <Container
        as={handleAs()}
        to={to}
        href={href}
        onClick={handleClick ? () => handleClick() : () => {}}
        ghost={ghost}
        disabled={disabled}
        className="cta"
        target={href && '_blank'}
        rel="noopener noreferrer"
        type={type}
        whiteHover={whiteHover}
      >
        {text}
      </Container>
    </Root>
  );
};

export default Button;

Button.propTypes = {
  text: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
  type: PropTypes.string,
  handleClick: PropTypes.func,
  ghost: PropTypes.bool,
  disabled: PropTypes.bool,
};

const Ghost = css`
  background-color: white;
  border: 1px solid ${({ theme }) => theme.color.navy};
  color: ${({ theme }) => theme.color.navy};
`;

const Root = styled.div`
  display: inline-block;
`;

const Container = styled.button`
  cursor: pointer;
  display: flex;
  width: max-content;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.green};
  border: 1px solid ${({ theme }) => theme.color.green};
  color: ${({ theme }) => theme.color.white};
  transition: all 0.4s ease;
  ${vw('height', 25, 40)}
  ${vw('padding-right', 12, 24)}
  ${vw('padding-left', 12, 24)}
  ${(props) => props.ghost && Ghost}
  @media ${media.desktop} {
    &:hover {
      background-color: ${({ theme, whiteHover }) =>
        whiteHover ? 'transparent' : theme.color.greenDark};
      border: 1px solid
        ${({ theme, whiteHover }) =>
          whiteHover ? theme.color.white : theme.color.greenDark};
    }
  }
`;

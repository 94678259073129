import React from 'react';
import { useState } from 'react';
import Helmet from 'react-helmet';

import OnUpdate from './OnUpdate';
import useIntl from 'components/useIntl';

import appConf from 'src/config/app.conf';
import seo from 'src/config/seo.conf';

const { locales } = appConf;

const SEO = () => {
  const [title, setTitle] = useState('');
  const { getLocaleURL } = useIntl();

  const updateTitle = (location) => {
    const texts = location.pathname.split('/');
    let newTitle = texts[texts.length - 1];

    // Checks if its on a localized home route
    if (locales.some((locale) => locale === newTitle.toLowerCase())) {
      newTitle = '';
    }

    // Creates a title based on the page route
    newTitle =
      newTitle === ''
        ? ''
        : !Object.keys(seo.routes).includes(newTitle)
        ? seo.routes['404'][localeIs()]
        : seo.routes[newTitle][localeIs()];
    setTitle(newTitle);
  };

  // Returns the current locale, formatted for the SEO config
  const localeIs = () => {
    return getLocaleURL() === '' ? 'en' : getLocaleURL().replace('/', '');
  };

  return (
    <>
      <Helmet
        titleTemplate={`%s | ${seo.title[localeIs()]}`}
        defaultTitle={`${title === '' ? '' : title + ' | '}${
          seo.title[localeIs()]
        }`}
      >
        <title>{`${title === '' ? '' : title}`}</title>
        {/* Title for all social media */}
        <meta
          name="title"
          property="og:title"
          content={`${title === '' ? '' : title + ' | '}${
            seo.title[localeIs()]
          }`}
        />
        <meta name="keywords" content={seo.keywords[localeIs()]} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta
          name="image"
          property="og:image"
          content={seo.image[localeIs()]}
        />
        <meta
          name="description"
          property="og:description"
          content={seo.description[localeIs()]}
        />
        <meta
          name="twitter:title"
          content={`${title === '' ? '' : title + ' | '}${
            seo.title[localeIs()]
          }`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={seo.image[localeIs()]} />
        <meta
          name="twitter:description"
          content={seo.description[localeIs()]}
        />
      </Helmet>
      <OnUpdate immediate call={updateTitle} />
    </>
  );
};

export default SEO;

// Docs: https://dwiki.bamdigital.com/configuring-seo/

import data from '../../package.json';

export default {
  // As long as there are locales other than the default, en, you must add them below.
  // SEO Title of the site.
  title: {
    en: 'Precidia Rentals',
  },
  // SEO Description of the site.
  description: {
    en: 'Brand-new studio and three-bedroom rental apartments ideally located in the heart of West Coquitlam.',
  },
  // SEO Keywords of the site.
  keywords: {
    en: 'Precidia, rental, coquitlam, west coquitlan, ledmac, ledingham mcallister, homes for rent, condo for rent, apartment for rent, Lougheed, burquitlam, pet-friendly',
  },
  // Open Graph image for the site. This is the image that will be used when sharing the site on social media, slack, etc.
  image: {
    // For most projects, we use only English open graph image. If you need to use a different image for another locale i.e. zh, add it below. It's currently set og-en.png for both locales.
    en: `${data.host}og-en.png`,
  },
  routes: {
    // You need to update this object every time you add a new route to the site. The key is the route name. The value is the SEO title of the route.
    404: {
      en: '404 Not Found',
    },
    'thank-you': {
      en: 'Thank You',
    },
    floorplans: {
      en: 'Find Your Home',
    },
    interiors: {
      en: 'Interiors',
    },
    amenities: {
      en: 'Building',
    },
    neighbourhood: {
      en: 'Neighbourhood',
    },
    gallery: {
      en: 'Gallery',
    },
    developer: {
      en: 'Team',
    },
    register: {
      en: 'Register',
    },
  },
};

import React, { Component } from 'react';

class Pixel extends Component {
  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://up.pixel.ad/assets/up.js?um=1';
    // script.onload = () => {
    //   if (window.cntrUpTag) {
    //     window.cntrUpTag.track('cntrData', '2db6a589bcdaf9c8');
    //   } else {
    //     console.error('cntrUpTag is still not defined after script load');
    //   }
    // };
    script.onload = () => {
      // console.log('Script loaded, window.cntrUpTag:', window.cntrUpTag);
      if (window.cntrUpTag) {
        // console.log('Attempting to send tracking data...');
        window.cntrUpTag.track('cntrData', '2db6a589bcdaf9c8');
        // console.log('Tracking data sent.');
      } else {
        console.error('cntrUpTag is still not defined after script load');
      }
    };

    script.onerror = () => {
      console.error('Script failed to load');
    };
    document.head.appendChild(script);
  }

  render() {
    return null; // This component does not render anything
  }
}

export default Pixel;

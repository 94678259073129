import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

import ArrowLeft from 'components/Icons/ArrowLeft';
import ArrowRight from 'components/Icons/ArrowRight';
import CloseIcon from 'src/assets/images/_global/icons/close.svg';

import vw from 'src/styles/utils';

// Slick styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Spotlight = (props) => {
  const { title, images, closeHandler, index, active } = props;
  const $slick = useRef(null);

  useEffect(() => {
    $slick.current.slickGoTo(index, true);
  });

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <StyledArrowRight navy />,
    prevArrow: <StyledArrowLeft navy />,
  };

  return (
    <Root
      style={{
        opacity: active ? 1 : 0,
        pointerEvents: active ? 'all' : 'none',
      }}
    >
      <Title className="darkGrey uppercase">{title}</Title>
      <Close onClick={closeHandler} src={CloseIcon} width="20" height="20" />
      <Slider {...settings} ref={$slick}>
        {images.map((image, index) => (
          <Card key={index}>
            <Img src={image.src} />
            <p className="darkGrey center">{image.alt}</p>
          </Card>
        ))}
      </Slider>
    </Root>
  );
};

export default Spotlight;

const Root = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 99;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: ${({ theme }) => theme.transition};
  ${vw('padding-top', 60, 0)}
  ${vw('justify-content', 'flex-start', 'center')}

  .slick-slider {
    width: 100%;
  }
`;

const Title = styled.h3`
  ${vw('margin-bottom', 90, 156, 40)}
  ${vw('margin-left', 10)}
  ${vw('margin-right', 10)}
`;

const Card = styled.div``;

const Img = styled.img`
  margin: auto;
  filter: drop-shadow(0px 2px 12px rgba(75, 78, 82, 0.4008));
  object-fit: cover;
  ${vw('margin-bottom', 60, 120, 12)};
  ${vw('width', 256, 'auto')}
  ${vw('height', 'auto', 433, 560)}
  ${vw('max-width', 'none', 648, 'none')}
`;

const Close = styled.img`
  cursor: pointer;
  position: absolute;
  ${vw('width', 20, 24)}
  ${vw('top', 20, 24)}
  ${vw('top', 20, 36)}
  ${vw('right', 20, 36)}
`;

const StyledArrowLeft = styled(ArrowLeft)`
  &.slick-prev {
    position: absolute;
    z-index: 10;
    ${vw('left', 10, 25, 45)}
    ${vw('top', 70, 220, 290)}
  }
`;

const StyledArrowRight = styled(ArrowRight)`
  &.slick-next {
    position: absolute;
    z-index: 10;
    ${vw('right', 10, 25, 45)}
    ${vw('top', 70, 220, 290)}
  }
`;
